import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Flight Ticket Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/flight-ticket-web-app/"
    metaDescription="Ready to spread your wings a little and design your own boarding pass website? Start prototyping with Uizard, the AI-powered UI design tool."
    description="
    h2:Introducing SkyPlane, Uizard's flight booking website design template
    <br/>
    Whether you are new to UX design or you are a UX pro, Uizard's <a:https://uizard.io/templates/>UX/UI design templates</a> are here the transform the way you design. SkyPlane, our brand-new flight booking website template comes packed with all the must-have screens included, meaning you can kick off your project faster than ever before. Simply sign up for Uizard Pro and select the template to get started.
    <br/>
    h3:Bring your vision to life with rapid, real-time collaboration
    <br/>
    Team working has never been easier. Invite colleagues to collaborate in real-time or share your Uizard project link with stakeholders to get instant feedback on your ideas. Transform your flight booking <a:https://uizard.io/templates/website-templates/>website design</a> idea into design reality faster than ever before.
    <br/>
    h3:Supercharge your productivity with Uizard's AI UI design features
    <br/>
    Looking to expedite the design process even further? Uizard has a range of <a:https://uizard.io/ai-design/>AI design</a> features to truly transform the way you design. Add screens to the flight booking website template by scanning in screenshots of established competitor sites or add your hand-drawn wireframes with Uizard Scanner. You can even update the theme of our templates from a screenshot or URL!
    "
    pages={[
      "A clean flight ticket app landing page mockup with a chic, streamlined aesthetic",
      "UI flows for user booking and seat selection",
      "Monthly/annual statement screens",
      "Stunning mockup screens for flight details and boarding pass presentation and scanning",
    ]}
    projectCode="xV7MEPYb53CZPAQqRbbl"
    img1={data.image1.childImageSharp}
    img1alt="flight booking web app cover"
    img2={data.image2.childImageSharp}
    img2alt="flight booking web app results from search screen"
    img3={data.image3.childImageSharp}
    img3alt="flight booking web app user booking flow"
    img4={data.image4.childImageSharp}
    img4alt="flight booking web app user booking finalization"
    img5={data.image5.childImageSharp}
    img5alt="flight booking web app summary screens"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/flight-booking-web-app/flight-booking-web-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/flight-booking-web-app/flight-booking-web-results.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/flight-booking-web-app/flight-booking-web-fare.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/flight-booking-web-app/flight-booking-web-services.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/flight-booking-web-app/flight-booking-web-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
